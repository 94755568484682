import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  Typography,
  AppBar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Menu,
  MenuItem as MenuOption
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NavBar from '../Navigation/Navigation';


interface Item {
  PLU_SKU: string;
  QTY_On_Hand: number;
  Size1_Code: string;
  Style_Code: string;
  Description_1: string;
  Department: string;
  Supplier_Code: string;
  Brand: string;
  Class: string;
  Color_Code: string;
  QTY_Send?: number;
  QTY_Received?: number;
}

interface FilterOptions {
  department?: string;
  category?: string;
  supplier?: string;
  brand?: string;
  styleCode?: string;
  size?: string;
  location?: string;
  transferNo?: string;
  status?: string; // Transfer Status
}

const ReprintTags: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [items, setItems] = useState<Item[]>([]);
  const [search, setSearch] = useState(''); 
  const [openPrepareFilter, setOpenPrepareFilter] = useState(false); 
  const [openTransferFilter, setOpenTransferFilter] = useState(false); 
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [appendToExistingList, setAppendToExistingList] = useState<boolean>(false);
  const [reprintQuantities, setReprintQuantities] = useState<{ [key: string]: number }>({});
  const [selectedQuantityOption, setSelectedQuantityOption] = useState<'SentQty' | 'ReceivedQty' | 'OnHandQty' | 'PrintOne'>('SentQty'); 
  const [transferStatus, setTransferStatus] = useState<'Sent' | 'Received'>('Sent'); 

  const [contextMenu, setContextMenu] = useState<null | { mouseX: number; mouseY: number; pluSku: string }>(null);
    // Refs for navigating between reprint quantity input fields
    const inputRefs = useRef<HTMLInputElement[]>([]);
    

      // Handle opening the custom context menu
      const handleRightClick = (event: React.MouseEvent<HTMLTableRowElement>, pluSku: string) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, pluSku }
            : null
        );
      };
      
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handlePrintSingleTag = async (pluSku: string) => {
    try {
      const qty = reprintQuantities[pluSku] || 1; // Default to 1 if no quantity is provided

      const response = await fetch('/print-label', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sku: pluSku,
          qty,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(`ZPL for ${pluSku}:`, data.zpl);
        sendZplToPrinter(data.zpl);
      } else {
        console.error(`Failed to print label for ${pluSku}:`, data.message);
      }
    } catch (error) {
      console.error('Error printing tags:', error);
    }
  };

  // Disable scroll wheel and arrow key increment/decrement on input
  const handleWheel = (e: React.WheelEvent) => {
    if (document.activeElement === e.currentTarget) {
      e.preventDefault();
    }
  };

  // Handle the search within table items
  const filteredItems = items.filter((item) =>
    item.PLU_SKU.toLowerCase().includes(search.toLowerCase()) ||
    item.Description_1.toLowerCase().includes(search.toLowerCase())
  );

  const handlePrintTags = async () => {
    try {
      // Iterate through each item and send the SKU and quantity to the backend API
      for (const item of filteredItems) {
        const qty = reprintQuantities[item.PLU_SKU] || 0; // Default to 1 if no quantity is provided

        const response = await fetch('/print-label', {
          method: 'POST',
          credentials:'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sku: item.PLU_SKU, // SKU from the table
            qty,               // Quantity to print
          }),
        });

        const data = await response.json();
        
        if (response.ok) {
          // You can log the ZPL to verify or send it to your printer
          console.log(`ZPL for ${item.PLU_SKU}:`, data.zpl);

          // Print logic (if using WebUSB or any other printing mechanism)
          // This is where you would send the ZPL to your printer
          sendZplToPrinter(data.zpl);
        } else {
          console.error(`Failed to print label for ${item.PLU_SKU}:`, data.message);
        }
      }
    } catch (error) {
      console.error('Error printing tags:', error);
    }
  };

  const sendZplToPrinter = (zpl: string) => {
    window.BrowserPrint.getLocalDevices((devices: any) => {
      console.log('Devices object:', devices);

      // Access the 'printer' array inside the 'devices' object
      if (devices && devices.printer && Array.isArray(devices.printer)) {
        const printers = devices.printer;

        console.log('Printers array length:', printers.length);

        // Check if there are any printers connected
        if (printers.length > 0) {
          printers.forEach((printer: any) => {
            console.log(`Printer: ${printer.name}, Connection: ${printer.connection}`);
          });

          // Find the USB-connected printer
          const printer = printers.find((device: any) => device.connection === 'usb');

          if (printer) {
            console.log('USB printer selected:', printer);
            // Now send ZPL to this printer
            printer.send(
              zpl,
              () => {
                //alert('Label sent to printer successfully.');
              },
              (error: string) => {
                console.error('Error sending ZPL to printer:', error);
                alert('Error sending label to printer: ' + error);
              }
            );
          } else {
            console.log('No USB-connected Zebra printer found.');
            alert('No USB-connected Zebra printer found.');
          }
        } else {
          console.log('No printers found.');
          alert('No printers found. Please check your printer connection.');
        }
      } else {
        console.error('No printers found in the devices object:', devices);
        alert('No printers found.');
      }
    }, 'printer');

  }

  // API Call Handler for "Prepare List"
  const handlePrepareApiCall = async () => {
    try {
      const response = await fetch('/reprintTags', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filterOptions),
      });

      const newData: Item[] = await response.json();
      if (!Array.isArray(newData)) {
        throw new Error('API response is not an array');
      }

      if (appendToExistingList) {
        const uniqueItems = [...items];
        newData.forEach((newItem) => {
          if (!uniqueItems.some((item) => item.PLU_SKU === newItem.PLU_SKU)) {
            uniqueItems.push(newItem);
          }
        });
        setItems(uniqueItems);
      } else {
        setItems(newData);
      }

      setOpenPrepareFilter(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // API Call Handler for "Transfer List"
  const handleTransferApiCall = async () => {
    try {
      // Include the selected transfer status in filterOptions
      const updatedFilterOptions = {
        ...filterOptions,
        status: transferStatus, // Add transfer status to the filter options
      };

      const response = await fetch('/byTR', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFilterOptions),
      });

      const newData: Item[] = await response.json();
      if (!Array.isArray(newData)) {
        throw new Error('API response is not an array');
      }

      if (appendToExistingList) {
        const uniqueItems = [...items];
        newData.forEach((newItem) => {
          if (!uniqueItems.some((item) => item.PLU_SKU === newItem.PLU_SKU)) {
            uniqueItems.push(newItem);
          }
        });
        setItems(uniqueItems);
      } else {
        setItems(newData);
      }

      // Automatically set Reprint Quantities based on the selected option
      if (selectedQuantityOption === 'SentQty') {
        const updatedQuantities: { [key: string]: number } = {};
        newData.forEach((item) => {
          if (item.QTY_Send) {
            updatedQuantities[item.PLU_SKU] = item.QTY_Send;
          }
        });
        setReprintQuantities(updatedQuantities);
      } else if (selectedQuantityOption === 'ReceivedQty') {
        const updatedQuantities: { [key: string]: number } = {};
        newData.forEach((item) => {
          if (item.QTY_Received) {
            updatedQuantities[item.PLU_SKU] = item.QTY_Received;
          }
        });
        setReprintQuantities(updatedQuantities);
      } else if (selectedQuantityOption === 'PrintOne') {
        const updatedQuantities: { [key: string]: number } = {};
        newData.forEach((item) => {
          updatedQuantities[item.PLU_SKU] = 1;
        });
        setReprintQuantities(updatedQuantities);
      }

      setOpenTransferFilter(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Handle updating the reprint quantity for each item
  const handleReprintQtyChange = (pluSku: string, qty: number) => {
    setReprintQuantities({
      ...reprintQuantities,
      [pluSku]: qty,
    });
  };

  // Clear all table data
  const clearTableData = () => {
    setItems([]);
    setReprintQuantities({});
  };

   // Function to remove a row
   const removeRow = (pluSku: string) => {
    setItems((prevItems) => prevItems.filter((item) => item.PLU_SKU !== pluSku));
    setReprintQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[pluSku];
      return newQuantities;
    });
  };

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  // Handle the arrow key navigation between inputs
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'ArrowDown' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (e.key === 'ArrowUp' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleDeleteRow = () => {
    if (contextMenu) {
      removeRow(contextMenu.pluSku);
      handleCloseContextMenu();
    }
  };
  const handlePrintRowTags = () => {
    if (contextMenu) {
      handlePrintSingleTag(contextMenu.pluSku);
      handleCloseContextMenu();
    }
  };

  return (
    <>
      <AppBar>
        <NavBar />
      </AppBar>
      <Box sx={{ marginTop: 8.5, height: '88vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Box
          sx={{
            flex: 1, // Take remaining space to make it scrollable
            backgroundColor: '#ccc',
            paddingTop: 1.5,
            borderRadius: '5px',
            overflowY: 'auto', // Make it scrollable
            borderBottom: '1px solid #ccc',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {['SKU', 'Description', 'Style', 'Size', 'Reprint Qty', 'Quantity', 'Brand', 'Department', 'Category'].map(
                  (header) => (
                    <TableCell
                      key={header}
                      sx={{
                        backgroundColor: '#FFFFF0',
                        border: '1px solid #ccc',
                        fontWeight: 'bold',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        padding: '5px',
                      }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item, index) => (
               <TableRow
               key={index}
               onContextMenu={(event) => handleRightClick(event, item.PLU_SKU)} // Handle right-click
               sx={{
                 backgroundColor: index % 2 === 0 ? '#FAF9F6' : '#D3D3D3',
                 '&:hover': {
                   backgroundColor: '#E0E0E0',
                 },
               }}
             >
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.PLU_SKU}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.Description_1}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.Style_Code}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.Size1_Code}
                  </TableCell>
                  <TableCell
                    sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px', width: '120px' }}
                  >
                    <TextField
                      type="number"
                      size="small"
                      variant="standard"
                      value={reprintQuantities[item.PLU_SKU] || ''}
                      onChange={(e) => handleReprintQtyChange(item.PLU_SKU, parseInt(e.target.value))}
                      onWheel={handleWheel} // Disable scroll increment
                      onKeyDown={handleKeyDownInput} // Disable up/down arrow increment
                      inputRef={(ref) => {
                        if (ref) inputRefs.current[index] = ref;
                      }}
                      InputProps={{
                        disableUnderline: true,
                        inputProps: {
                          style: {
                            textAlign: 'center',
                            width: '100px',
                            appearance: 'textfield',
                            WebkitAppearance: 'none',
                            MozAppearance: 'textfield',
                          } as React.CSSProperties,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.QTY_On_Hand}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.Brand}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.Department}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
                    {item.Class}
                  </TableCell>
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuOption onClick={handleDeleteRow}>Delete Row</MenuOption>
          <MenuOption onClick={handlePrintRowTags}>Print Tags</MenuOption>
        </Menu>
        {/* Prepare List Dialog */}
        <Dialog open={openPrepareFilter} onClose={() => setOpenPrepareFilter(false)} fullWidth>
          <DialogTitle>Prepare List</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {[
                { label: 'Department', value: 'department' },
                { label: 'Category', value: 'category' },
                { label: 'Brand', value: 'brand' },
                { label: 'Supplier', value: 'supplier' },
                { label: 'Style', value: 'styleCode' },
                { label: 'Size', value: 'size' },
              ].map((field) => (
                <Grid item xs={12} key={field.value}>
                  <TextField
                    label={field.label}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={filterOptions[field.value as keyof FilterOptions] || ''}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        [field.value]: e.target.value,
                      })
                    }
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography>Append to Existing List?</Typography>
                <Button
                  variant={appendToExistingList ? 'contained' : 'outlined'}
                  onClick={() => setAppendToExistingList(!appendToExistingList)}
                >
                  {appendToExistingList ? 'Yes' : 'No'}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPrepareFilter(false)}>Cancel</Button>
            <Button onClick={handlePrepareApiCall}>Apply</Button>
          </DialogActions>
        </Dialog>

        {/* Transfer List Dialog */}
        <Dialog open={openTransferFilter} onClose={() => setOpenTransferFilter(false)} fullWidth>
          <DialogTitle>Transfer List</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Transfer No"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={filterOptions.transferNo || ''}
                  onChange={(e) => setFilterOptions({ ...filterOptions, transferNo: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={transferStatus}
                    onChange={(e) => setTransferStatus(e.target.value as 'Sent' | 'Received')}
                  >
                    <MenuItem value="Sent">Sent</MenuItem>
                    <MenuItem value="Received">Received</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Reprint Quantity</InputLabel>
                  <Select
                    value={selectedQuantityOption}
                    onChange={(e) => setSelectedQuantityOption(e.target.value as 'SentQty' | 'ReceivedQty' | 'OnHandQty' | 'PrintOne')}
                  >
                    <MenuItem value="SentQty">Sent Qty</MenuItem>
                    <MenuItem value="ReceivedQty">Received Qty</MenuItem>
                    <MenuItem value="OnHandQty">On Hand Qty</MenuItem>
                    <MenuItem value="PrintOne">Print One of Each</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography>Append to Existing List?</Typography>
                <Button
                  variant={appendToExistingList ? 'contained' : 'outlined'}
                  onClick={() => setAppendToExistingList(!appendToExistingList)}
                >
                  {appendToExistingList ? 'Yes' : 'No'}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenTransferFilter(false)}>Cancel</Button>
            <Button onClick={handleTransferApiCall}>Apply</Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* Search, Filter, and Clear Section */}
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: '#fff',
            borderTop: '1px solid #ccc',
            position: 'sticky',
            bottom: 0,
            zIndex: 1000,
          }}
        >
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size='small'
          sx={{ marginLeft: '15px',width:'40%',height:'10%' }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setOpenPrepareFilter(true);
          }}
          sx={{ marginLeft: '10px', width:'15%', height:'20%' }}
        >
          Prepare List
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpenTransferFilter(true);
          }}
          sx={{ marginLeft: '10px',width:'15%', height:'20%' }}
        >
          Transfer List
        </Button>
        <Button variant="contained" color="success" onClick={handlePrintTags} sx={{ marginLeft: '10px',width:'15%', height:'20%'}}>
          Print Tags
        </Button>
        <Button variant="contained" color="secondary" onClick={clearTableData} sx={{ marginLeft: '10px',width:'15%', height:'20%' }}>
          Clear Data
        </Button>
      </Box>
    </>
  );
};

export default ReprintTags;

// import React, { useRef, useState } from 'react';
// import {
//   Box,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TextField,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Grid,
//   useMediaQuery,
//   Typography,
//   AppBar,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   IconButton,
//   Menu,
//   MenuItem as MenuOption
// } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import NavBar from '../Navigation/Navigation';


// interface Item {
//   PLU_SKU: string;
//   QTY_On_Hand: number;
//   Size1_Code: string;
//   Style_Code: string;
//   Description_1: string;
//   Department: string;
//   Supplier_Code: string;
//   Brand: string;
//   Class: string;
//   Color_Code: string;
//   QTY_Send?: number;
//   QTY_Received?: number;
// }

// interface FilterOptions {
//   department?: string;
//   category?: string;
//   supplier?: string;
//   brand?: string;
//   styleCode?: string;
//   size?: string;
//   location?: string;
//   transferNo?: string;
//   status?: string; // Transfer Status
// }

// const ReprintTags: React.FC = () => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
//   const [items, setItems] = useState<Item[]>([]);
//   const [search, setSearch] = useState(''); 
//   const [openPrepareFilter, setOpenPrepareFilter] = useState(false); 
//   const [openTransferFilter, setOpenTransferFilter] = useState(false); 
//   const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
//   const [appendToExistingList, setAppendToExistingList] = useState<boolean>(false);
//   const [reprintQuantities, setReprintQuantities] = useState<{ [key: string]: number }>({});
//   const [selectedQuantityOption, setSelectedQuantityOption] = useState<'SentQty' | 'ReceivedQty' | 'OnHandQty' | 'PrintOne'>('SentQty'); 
//   const [transferStatus, setTransferStatus] = useState<'Sent' | 'Received'>('Sent'); 

//   const [contextMenu, setContextMenu] = useState<null | { mouseX: number; mouseY: number; pluSku: string }>(null);
//     // Refs for navigating between reprint quantity input fields
//     const inputRefs = useRef<HTMLInputElement[]>([]);
    

//       // Handle opening the custom context menu
//       const handleRightClick = (event: React.MouseEvent<HTMLTableRowElement>, pluSku: string) => {
//         event.preventDefault();
//         setContextMenu(
//           contextMenu === null
//             ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, pluSku }
//             : null
//         );
//       };
      
//   const handleCloseContextMenu = () => {
//     setContextMenu(null);
//   };

//   const handlePrintSingleTag = async (pluSku: string) => {
//     try {
//       const qty = reprintQuantities[pluSku] || 1; // Default to 1 if no quantity is provided

//       const response = await fetch('/print-label', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           sku: pluSku,
//           qty,
//         }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         console.log(`ZPL for ${pluSku}:`, data.zpl);
//         sendZplToPrinter(data.zpl);
//       } else {
//         console.error(`Failed to print label for ${pluSku}:`, data.message);
//       }
//     } catch (error) {
//       console.error('Error printing tags:', error);
//     }
//   };

//   // Disable scroll wheel and arrow key increment/decrement on input
//   const handleWheel = (e: React.WheelEvent) => {
//     if (document.activeElement === e.currentTarget) {
//       e.preventDefault();
//     }
//   };

//   // Handle the search within table items
//   const filteredItems = items.filter((item) =>
//     item.PLU_SKU.toLowerCase().includes(search.toLowerCase()) ||
//     item.Description_1.toLowerCase().includes(search.toLowerCase())
//   );

//   // const handlePrintTags = async () => {
//   //   try {
//   //     // Iterate through each item and send the SKU and quantity to the backend API
//   //     for (const item of filteredItems) {
//   //       const qty = reprintQuantities[item.PLU_SKU] || 0; // Default to 1 if no quantity is provided

//   //       const response = await fetch('/print-label', {
//   //         method: 'POST',
//   //         credentials:'include',
//   //         headers: {
//   //           'Content-Type': 'application/json',
//   //         },
//   //         body: JSON.stringify({
//   //           sku: item.PLU_SKU, // SKU from the table
//   //           qty,               // Quantity to print
//   //         }),
//   //       }); 

//   //       const data = await response.json();
        
//   //       if (response.ok) {
//   //         // You can log the ZPL to verify or send it to your printer
//   //         console.log(`ZPL for ${item.PLU_SKU}:`, data.zpl);

//   //         // Print logic (if using WebUSB or any other printing mechanism)
//   //         // This is where you would send the ZPL to your printer
//   //         sendZplToPrinter(data.zpl);
//   //       } else {
//   //         console.error(`Failed to print label for ${item.PLU_SKU}:`, data.message);
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.error('Error printing tags:', error);
//   //   }
//   // }; 
//   const handlePrintTags = async () => {
//     try {
//         // Sort items by Style_Code and Size1_Code before sending request
//         const sortedItems = [...filteredItems].sort((a, b) => 
//             a.Style_Code.localeCompare(b.Style_Code) || a.Size1_Code.localeCompare(b.Size1_Code)
//         );

//         for (const item of sortedItems) {
//             const qtyForSizes = {
//                 [item.Size1_Code]: reprintQuantities[item.PLU_SKU] || 1, // Ensure quantity exists
//             };

//             const response = await fetch('/print-label', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     sku: item.PLU_SKU, 
//                     qty: qtyForSizes, // Send quantity by size
//                 }),
//             });

//             const data = await response.json();

//             if (response.ok) {
//                 sendZplToPrinter(data.zpl);
//             } else {
//                 console.error(`Failed to print label for ${item.PLU_SKU}:`, data.message);
//             }
//         }
//     } catch (error) {
//         console.error('Error printing tags:', error);
//     }
// };

 
//   const sendZplToPrinter = (zpl: string) => {
//     window.BrowserPrint.getLocalDevices((devices: any) => {
//       console.log('Devices object:', devices);

//       // Access the 'printer' array inside the 'devices' object
//       if (devices && devices.printer && Array.isArray(devices.printer)) {
//         const printers = devices.printer;

//         console.log('Printers array length:', printers.length);

//         // Check if there are any printers connected
//         if (printers.length > 0) {
//           printers.forEach((printer: any) => {
//             console.log(`Printer: ${printer.name}, Connection: ${printer.connection}`);
//           });

//           // Find the USB-connected printer
//           const printer = printers.find((device: any) => device.connection === 'usb');

//           if (printer) {
//             console.log('USB printer selected:', printer);
//             // Now send ZPL to this printer
//             printer.send(
//               zpl,
//               () => {
//                 //alert('Label sent to printer successfully.');
//               },
//               (error: string) => {
//                 console.error('Error sending ZPL to printer:', error);
//                 alert('Error sending label to printer: ' + error);
//               }
//             );
//           } else {
//             console.log('No USB-connected Zebra printer found.');
//             alert('No USB-connected Zebra printer found.');
//           }
//         } else {
//           console.log('No printers found.');
//           alert('No printers found. Please check your printer connection.');
//         }
//       } else {
//         console.error('No printers found in the devices object:', devices);
//         alert('No printers found.');
//       }
//     }, 'printer');

//   }

//   // API Call Handler for "Prepare List"
//   const handlePrepareApiCall = async () => {
//     try {
//       const response = await fetch('/reprintTags', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(filterOptions),
//       });

//       const newData: Item[] = await response.json();
//       if (!Array.isArray(newData)) {
//         throw new Error('API response is not an array');
//       }

//       if (appendToExistingList) {
//         const uniqueItems = [...items];
//         newData.forEach((newItem) => {
//           if (!uniqueItems.some((item) => item.PLU_SKU === newItem.PLU_SKU)) {
//             uniqueItems.push(newItem);
//           }
//         });
//         setItems(uniqueItems);
//       } else {
//         setItems(newData);
//       }

//       setOpenPrepareFilter(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   // API Call Handler for "Transfer List"
//   const handleTransferApiCall = async () => {
//     try {
//       // Include the selected transfer status in filterOptions
//       const updatedFilterOptions = {
//         ...filterOptions,
//         status: transferStatus, // Add transfer status to the filter options
//       };

//       const response = await fetch('/byTR', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedFilterOptions),
//       });

//       const newData: Item[] = await response.json();
//       if (!Array.isArray(newData)) {
//         throw new Error('API response is not an array');
//       }

//       if (appendToExistingList) {
//         const uniqueItems = [...items];
//         newData.forEach((newItem) => {
//           if (!uniqueItems.some((item) => item.PLU_SKU === newItem.PLU_SKU)) {
//             uniqueItems.push(newItem);
//           }
//         });
//         setItems(uniqueItems);
//       } else {
//         setItems(newData);
//       }

//       // Automatically set Reprint Quantities based on the selected option
//       if (selectedQuantityOption === 'SentQty') {
//         const updatedQuantities: { [key: string]: number } = {};
//         newData.forEach((item) => {
//           if (item.QTY_Send) {
//             updatedQuantities[item.PLU_SKU] = item.QTY_Send;
//           }
//         });
//         setReprintQuantities(updatedQuantities);
//       } else if (selectedQuantityOption === 'ReceivedQty') {
//         const updatedQuantities: { [key: string]: number } = {};
//         newData.forEach((item) => {
//           if (item.QTY_Received) {
//             updatedQuantities[item.PLU_SKU] = item.QTY_Received;
//           }
//         });
//         setReprintQuantities(updatedQuantities);
//       } else if (selectedQuantityOption === 'PrintOne') {
//         const updatedQuantities: { [key: string]: number } = {};
//         newData.forEach((item) => {
//           updatedQuantities[item.PLU_SKU] = 1;
//         });
//         setReprintQuantities(updatedQuantities);
//       }

//       setOpenTransferFilter(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   // Handle updating the reprint quantity for each item
//   const handleReprintQtyChange = (pluSku: string, qty: number) => {
//     setReprintQuantities({
//       ...reprintQuantities,
//       [pluSku]: qty,
//     });
//   };

//   // Clear all table data
//   const clearTableData = () => {
//     setItems([]);
//     setReprintQuantities({});
//   };

//    // Function to remove a row
//    const removeRow = (pluSku: string) => {
//     setItems((prevItems) => prevItems.filter((item) => item.PLU_SKU !== pluSku));
//     setReprintQuantities((prevQuantities) => {
//       const newQuantities = { ...prevQuantities };
//       delete newQuantities[pluSku];
//       return newQuantities;
//     });
//   };

//   const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
//       e.preventDefault();
//     }
//   };

//   // Handle the arrow key navigation between inputs
//   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
//     if (e.key === 'ArrowDown' && index < inputRefs.current.length - 1) {
//       inputRefs.current[index + 1]?.focus();
//     } else if (e.key === 'ArrowUp' && index > 0) {
//       inputRefs.current[index - 1]?.focus();
//     }
//   };

//   const handleDeleteRow = () => {
//     if (contextMenu) {
//       removeRow(contextMenu.pluSku);
//       handleCloseContextMenu();
//     }
//   };
//   const handlePrintRowTags = () => {
//     if (contextMenu) {
//       handlePrintSingleTag(contextMenu.pluSku);
//       handleCloseContextMenu();
//     }
//   };

//   return (
//     <>
//       <AppBar>
//         <NavBar />
//       </AppBar>
//       <Box sx={{ marginTop: 8.5, height: '88vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
//         <Box
//           sx={{
//             flex: 1, // Take remaining space to make it scrollable
//             backgroundColor: '#ccc',
//             paddingTop: 1.5,
//             borderRadius: '5px',
//             overflowY: 'auto', // Make it scrollable
//             borderBottom: '1px solid #ccc',
//           }}
//         >
//           <Table stickyHeader>
//             <TableHead>
//               <TableRow>
//                 {['SKU', 'Description', 'Style', 'Size', 'Reprint Qty', 'Quantity', 'Brand', 'Department', 'Category'].map(
//                   (header) => (
//                     <TableCell
//                       key={header}
//                       sx={{
//                         backgroundColor: '#FFFFF0',
//                         border: '1px solid #ccc',
//                         fontWeight: 'bold',
//                         fontSize: '12pt',
//                         fontFamily: 'Arial',
//                         padding: '5px',
//                       }}
//                     >
//                       {header}
//                     </TableCell>
//                   )
//                 )}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {filteredItems.map((item, index) => (
//                <TableRow
//                key={index}
//                onContextMenu={(event) => handleRightClick(event, item.PLU_SKU)} // Handle right-click
//                sx={{
//                  backgroundColor: index % 2 === 0 ? '#FAF9F6' : '#D3D3D3',
//                  '&:hover': {
//                    backgroundColor: '#E0E0E0',
//                  },
//                }}
//              >
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.PLU_SKU}
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.Description_1}
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.Style_Code}
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.Size1_Code}
//                   </TableCell>
//                   <TableCell
//                     sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px', width: '120px' }}
//                   >
//                     <TextField
//                       type="number"
//                       size="small"
//                       variant="standard"
//                       value={reprintQuantities[item.PLU_SKU] || ''}
//                       onChange={(e) => handleReprintQtyChange(item.PLU_SKU, parseInt(e.target.value))}
//                       onWheel={handleWheel} // Disable scroll increment
//                       onKeyDown={handleKeyDownInput} // Disable up/down arrow increment
//                       inputRef={(ref) => {
//                         if (ref) inputRefs.current[index] = ref;
//                       }}
//                       InputProps={{
//                         disableUnderline: true,
//                         inputProps: {
//                           style: {
//                             textAlign: 'center',
//                             width: '100px',
//                             appearance: 'textfield',
//                             WebkitAppearance: 'none',
//                             MozAppearance: 'textfield',
//                           } as React.CSSProperties,
//                         },
//                       }}
//                     />
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.QTY_On_Hand}
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.Brand}
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.Department}
//                   </TableCell>
//                   <TableCell sx={{ border: '1px solid #ccc', fontSize: '12pt', fontFamily: 'Arial', padding: '5px' }}>
//                     {item.Class}
//                   </TableCell>
                
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </Box>
//         <Menu
//           open={contextMenu !== null}
//           onClose={handleCloseContextMenu}
//           anchorReference="anchorPosition"
//           anchorPosition={
//             contextMenu !== null
//               ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
//               : undefined
//           }
//         >
//           <MenuOption onClick={handleDeleteRow}>Delete Row</MenuOption>
//           <MenuOption onClick={handlePrintRowTags}>Print Tags</MenuOption>
//         </Menu>
//         {/* Prepare List Dialog */}
//         <Dialog open={openPrepareFilter} onClose={() => setOpenPrepareFilter(false)} fullWidth>
//           <DialogTitle>Prepare List</DialogTitle>
//           <DialogContent>
//             <Grid container spacing={2}>
//               {[
//                 { label: 'Department', value: 'department' },
//                 { label: 'Category', value: 'category' },
//                 { label: 'Brand', value: 'brand' },
//                 { label: 'Supplier', value: 'supplier' },
//                 { label: 'Style', value: 'styleCode' },
//                 { label: 'Size', value: 'size' },
//               ].map((field) => (
//                 <Grid item xs={12} key={field.value}>
//                   <TextField
//                     label={field.label}
//                     fullWidth
//                     variant="outlined"
//                     size="small"
//                     value={filterOptions[field.value as keyof FilterOptions] || ''}
//                     onChange={(e) =>
//                       setFilterOptions({
//                         ...filterOptions,
//                         [field.value]: e.target.value,
//                       })
//                     }
//                   />
//                 </Grid>
//               ))}
//               <Grid item xs={12}>
//                 <Typography>Append to Existing List?</Typography>
//                 <Button
//                   variant={appendToExistingList ? 'contained' : 'outlined'}
//                   onClick={() => setAppendToExistingList(!appendToExistingList)}
//                 >
//                   {appendToExistingList ? 'Yes' : 'No'}
//                 </Button>
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenPrepareFilter(false)}>Cancel</Button>
//             <Button onClick={handlePrepareApiCall}>Apply</Button>
//           </DialogActions>
//         </Dialog>

//         {/* Transfer List Dialog */}
//         <Dialog open={openTransferFilter} onClose={() => setOpenTransferFilter(false)} fullWidth>
//           <DialogTitle>Transfer List</DialogTitle>
//           <DialogContent>
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <TextField
//                   label="Transfer No"
//                   fullWidth
//                   variant="outlined"
//                   size="small"
//                   value={filterOptions.transferNo || ''}
//                   onChange={(e) => setFilterOptions({ ...filterOptions, transferNo: e.target.value })}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <FormControl fullWidth>
//                   <InputLabel>Status</InputLabel>
//                   <Select
//                     value={transferStatus}
//                     onChange={(e) => setTransferStatus(e.target.value as 'Sent' | 'Received')}
//                   >
//                     <MenuItem value="Sent">Sent</MenuItem>
//                     <MenuItem value="Received">Received</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12}>
//                 <FormControl fullWidth>
//                   <InputLabel>Reprint Quantity</InputLabel>
//                   <Select
//                     value={selectedQuantityOption}
//                     onChange={(e) => setSelectedQuantityOption(e.target.value as 'SentQty' | 'ReceivedQty' | 'OnHandQty' | 'PrintOne')}
//                   >
//                     <MenuItem value="SentQty">Sent Qty</MenuItem>
//                     <MenuItem value="ReceivedQty">Received Qty</MenuItem>
//                     <MenuItem value="OnHandQty">On Hand Qty</MenuItem>
//                     <MenuItem value="PrintOne">Print One of Each</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography>Append to Existing List?</Typography>
//                 <Button
//                   variant={appendToExistingList ? 'contained' : 'outlined'}
//                   onClick={() => setAppendToExistingList(!appendToExistingList)}
//                 >
//                   {appendToExistingList ? 'Yes' : 'No'}
//                 </Button>
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenTransferFilter(false)}>Cancel</Button>
//             <Button onClick={handleTransferApiCall}>Apply</Button>
//           </DialogActions>
//         </Dialog>
//       </Box>

//       {/* Search, Filter, and Clear Section */}
//       <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             padding: '10px',
//             backgroundColor: '#fff',
//             borderTop: '1px solid #ccc',
//             position: 'sticky',
//             bottom: 0,
//             zIndex: 1000,
//           }}
//         >
//         <TextField
//           label="Search"
//           variant="outlined"
//           fullWidth
//           value={search}
//           onChange={(e) => setSearch(e.target.value)}
//           size='small'
//           sx={{ marginLeft: '15px',width:'40%',height:'10%' }}
//         />
//         <Button
//           variant="contained"
//           onClick={() => {
//             setOpenPrepareFilter(true);
//           }}
//           sx={{ marginLeft: '10px', width:'15%', height:'20%' }}
//         >
//           Prepare List
//         </Button>
//         <Button
//           variant="contained"
//           onClick={() => {
//             setOpenTransferFilter(true);
//           }}
//           sx={{ marginLeft: '10px',width:'15%', height:'20%' }}
//         >
//           Transfer List
//         </Button>
//         <Button variant="contained" color="success" onClick={handlePrintTags} sx={{ marginLeft: '10px',width:'15%', height:'20%'}}>
//           Print Tags
//         </Button>
//         <Button variant="contained" color="secondary" onClick={clearTableData} sx={{ marginLeft: '10px',width:'15%', height:'20%' }}>
//           Clear Data
//         </Button>
//       </Box>
//     </>
//   );
// };

// export default ReprintTags;
